<template>
  <main class="about-page">
    <a class="whatsappWrapper" href="https://wa.me/+917579175791?text=I'm%20inquiring%20about%20the%20properties%20from%20Website!"><img src="../assets/img/WhatsApp.svg"/></a>
    <div class="container">
      <!-- /.main-hero -->
      <!-- Right Text Section -->
      <div class="about-us">
        <div class="container">
          <div class="block-1">
            <div class="content">
              <h2>The Gym</h2>
              <p>
                The Health Zone caters to everyone from those new to exercise,
                the fitness conscious, to the performance athletes. At Time
                Square Club, it’s not just about weight loss or building muscle.
                We take a holistic approach to health and fitness by developing
                an exclusive program for total body wellness.
              </p>
            </div>
          </div>
          <!-- /.block-1 -->

          <div class="block-2">
            <div class="about-image">
              <img
                src="../assets/img/final_3d_images/low/img010.jpg"
                alt="The Gym"
                class="img-cover"
              />
            </div>
          </div>

          <!-- /.block-2 -->

          <div class="clearfix"></div>
        </div>
        <!-- /.container -->
      </div>

      <!-- Left Text Section -->
      <div class="about-us">
        <div class="container">
          <div class="block-1" style="margin: 0px; width: 43%">
            <div class="about-image">
              <img
                src="../assets/img/final_3d_images/low/img065.jpg"
                alt="Yoga"
                class="img-cover"
              />
            </div>
          </div>
          <!-- /.block-1 -->

          <div
            style="margin-left: 100px; weight: calc(43% - 200px)"
            class="block-2"
          >
            <div class="content">
              <h2>Yoga</h2>
              <p>
                It is a very ancient discipline, born in regions that today make
                up northern India. Time Square Club’s aims to help individuals
                lead a happier, more natural life and so discover how to evolve
                spiritually. It believes that physical and psychological
                wellbeing are enhanced by coming to know oneself at all levels –
                body, breath, emotions, mind and beyond. originally it was an
                ascetic and meditative practice. It serves to maintain control
                over one's sensations and drives, to recirculate vital energy
                with the movement of the body, to harmonize both the body and
                the mind, and the individual with the universe and its energy,
                through meditation.
              </p>
            </div>
          </div>
          <!-- /.block-2 -->

          <div class="clearfix"></div>
        </div>
        <!-- /.container -->
      </div>

      <!-- Right Text Section -->
      <div class="about-us">
        <div class="container">
          <div class="block-1">
            <div class="content">
              <h2>Aerobics &amp; Zumba</h2>
              <p>
                It is a dance that mixes Caribbean and Cuban rhythms, steps of
                merengue and salsa and fitness movements for an aerobic lesson
                that involves all the muscles of the body. It is therefore a low
                impact work that stimulates the cardiovascular system and also
                allows toning and losing weight. The lessons are held in groups,
                under the guidance of an instructor who performs, step by step,
                a complete choreography.
              </p>
            </div>
          </div>
          <!-- /.block-1 -->

          <div class="block-2">
            <div class="about-image">
              <img
                src="../assets/img/final_3d_images/low/img031.jpg"
                alt="Aerobics &amp; Zumba"
                class="img-cover"
              />
            </div>
          </div>

          <!-- /.block-2 -->

          <div class="clearfix"></div>
        </div>
        <!-- /.container -->
      </div>

      <!-- Full Width Image -->
      <img
        src="../assets/img/final_3d_images/low/img025.jpg"
        alt="Gym Overview"
        class="about-page-img img-cover"
      />

      <!-- /.info -->
    </div>
    <!-- /.container -->
  </main>
  <!-- /main -->
</template>

<script>
export default {
  name: "",
};
</script>

<style>
</style>